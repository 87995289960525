import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage, getImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { heading300, paragraphTextMedium } from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";
import Button from "../shared/Button/Button";

const title = `On the Board with Plastometrex`;
const byline = `Enjoy our series of short-duration videos on materials, mechanics and mechanical testing!`;
const buttonText = `View all videos`;

const Videos = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: { relativePath: { regex: "/industry/videos/" } }
        sort: { order: ASC, fields: name }
      ) {
        edges {
          node {
            childImageSharp {
              gatsbyImageData(width: 600, placeholder: BLURRED)
            }
          }
        }
      }
    }
  `);

  const links = [
    "https://vimeo.com/572623899",
    "https://vimeo.com/572626399",
    "https://vimeo.com/572638762",
  ];

  const videos = data.allFile.edges.map((edge: any, index: number) => ({
    ...edge,
    link: links[index],
  }));
  return (
    <Container>
      <Title>{title}</Title>
      <Byline>{byline}</Byline>
      <Link to="/videos">
        <Button size="medium" tier="secondary" arrow noPadding>
          {buttonText}
        </Button>
      </Link>
      <VideosContainer>
        {videos.map((edge: any) => (
          <ImageContainer
            href={edge.link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <GatsbyImage
              image={getImage(edge.node) as IGatsbyImageData}
              alt=""
            />
          </ImageContainer>
        ))}
      </VideosContainer>
    </Container>
  );
};

export default Videos;

const Container = styled("div", {
  width: "90%",
  margin: "auto",
  display: "flex",
  flexDirection: "column",
  marginTop: 90,
  marginBottom: 50,
  "@md": {
    textAlign: "center",
    alignItems: "center",
  },
});
const Title = styled("h4", {
  ...heading300,
  margin: 0,
  marginBottom: 11,
});
const Byline = styled("p", {
  ...paragraphTextMedium,
  margin: 0,
  marginBottom: 23,
});
const VideosContainer = styled("div", {
  display: "none",
  justifyContent: "space-between",
  marginTop: 38,
  "@md": {
    display: "flex",
  },
});
const ImageContainer = styled("a", {
  width: "32%",
});
