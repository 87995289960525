import React from "react";
import {
  heading100Light,
  heading50,
  paragraphTextSmall,
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";

interface Props {
  points: {
    icon: JSX.Element;
    title: string;
    description: string;
  }[];
}

const DotPointsWithIcons = ({ points }: Props) => {
  return (
    <Container>
      {points.map((point) => (
        <Icon key={point.title}>
          <IconWrapper>{point.icon}</IconWrapper>
          <Title>{point.title}</Title>
          <Description>{point.description}</Description>
        </Icon>
      ))}
    </Container>
  );
};

export default DotPointsWithIcons;

const Container = styled("div", {
  width: "90%",
  margin: "auto",
  "@md": {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    paddingTop: 50,
    maxWidth: 800,
  },
  "@xl": {
    maxWidth: 1240,
  },
});
const Icon = styled("div", {
  display: "flex",
  alignItems: "center",
  marginBottom: 16,
  "@md": {
    maxWidth: 280,
    flexDirection: "column",
    flex: "1 0 34%",
    textAlign: "center",
    marginBottom: 40,
  },
});
const IconWrapper = styled("div", {
  width: 32,
  height: 32,
  "& svg": {
    width: "100%",
    height: "100%",
  },
  "@md": {
    width: 64,
    height: 64,
  },
});
const Title = styled("h6", {
  ...heading50,
  margin: 0,
  marginLeft: 10,
  color: "$blue100",
  "@md": {
    ...heading100Light,
    color: "$black100",
    marginTop: 37,
    marginBottom: 8,
  },
});
const Description = styled("p", {
  ...paragraphTextSmall,
  display: "none",
  "@md": {
    display: "block",
    margin: 0,
  },
});
