import { Link } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import {
  heading200,
  heading400,
  paragraphTextLarge,
  paragraphTextSmall,
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";
import Button from "./Button/Button";

const demoText = `Book Demo`;

interface Props {
  title: string;
  byline: string;
  secondaryButton: {
    text: string;
    path: string;
  };
  image: IGatsbyImageData;
  imageAlt?: string;
}

const FullScreenHero = ({
  title,
  byline,
  secondaryButton,
  image,
  imageAlt,
}: Props) => {
  return (
    <Wrapper>
      <Container>
        <TextContent>
          <Title>{title}</Title>
          <Byline>{byline}</Byline>
          <ButtonsContainer>
            <Link to="/book-a-demo">
              <Button size="large" tier="primary">
                {demoText}
              </Button>
            </Link>
            <Link to={secondaryButton.path}>
              <Button size="large" tier="secondary" arrow inverted>
                {secondaryButton.text}
              </Button>
            </Link>
          </ButtonsContainer>
        </TextContent>
      </Container>
      <ImageWrapper>
        <GatsbyImage image={image} alt={imageAlt ? imageAlt : ""} />
      </ImageWrapper>
    </Wrapper>
  );
};

export default FullScreenHero;

const Wrapper = styled("div", {
  position: "relative",
  "@md": {
    height: 600,
    marginTop: -80,
    display: "flex",
    backgroundColor: "#090909",
  },
});
const Container = styled("div", {
  maxWidth: 1200,
  width: "90%",
  margin: "auto",
});
const TextContent = styled("div", {
  position: "relative",
  zIndex: 2,
  "@md": {
    maxWidth: 428,
  },
});
const Title = styled("h1", {
  ...heading200,
  "@md": {
    ...heading400,
    color: "$white",
    fontWeight: 700,
    letterSpacing: -1.5,
    marginBottom: 20,
  },
});
const Byline = styled("p", {
  ...paragraphTextSmall,
  "@md": {
    ...paragraphTextLarge,
    color: "$white",
  },
});
const ImageWrapper = styled("div", {
  position: "absolute",
  width: "100%",
  height: 600,
  top: 0,
  right: 0,
  zIndex: 0,
  justifyContent: "flex-end",
  display: "none",
  "@md": {
    display: "flex",
  },
});
const ButtonsContainer = styled("div", {
  alignItems: "center",
  display: "none",
  "@md": {
    display: "flex",
  },
});
