import { graphql, useStaticQuery } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import React from "react";
import Layout from "../components/Layout";
import DotPointsWithIcons from "../components/shared/DotPointsWithIcons";
import FullScreenHero from "../components/shared/FullScreenHero";
import { styled } from "../stitches/stitches.config";

import PieChart from "../components/industry/assets/pie_chart.inline.svg";
import Squares from "../components/industry/assets/squares_b.inline.svg";
import BarChart from "../components/industry/assets/bar_chart.inline.svg";
import Rocket from "../components/industry/assets/rocket.inline.svg";
import TextButtonsImage from "../components/shared/TextButtonsImage";
import Testimonial from "../components/shared/Testominial/Testimonial";
import KnowledgeCentrePreview from "../components/knowledgeCentre/KnowledgeCentrePreview";
import Newsletter from "../components/shared/Newsletter/Newsletter";
import Videos from "../components/industry/Videos";

const seo = {
  title: "Academia",
  description:
    "Plastometex was founded by a team of materials science researchers from The University of Cambridge. Our products support ground breaking resarch in the areas of metallurgy, additive manufacturing, mechanics and mechanical testing.",
};

const heroTitle = `We’re here to support ground-breaking research`;
const heroByline = `Unlocking new insights, enhancing research, and accelerating innovation.`;
const heroSecondaryButton = {
  text: "Request a quote",
  path: "/request-a-quote",
};

const blockTitle = `We are a team of scientists, just like you!`;
const blockByline = `We understand the importance of experimental test equipment and the role it can play in ground-breaking research. We're here to help.`;
const blockSecondaryButton = {
  text: "Request a quote",
  path: "/request-a-quote",
};
const blockImageAlt = `Professor Bill Clyne and Dr James Dean, materials scientists at Plastometrex.`;

const testimonial = {
  name: "Dr Danielle Cote",
  position:
    "Dr. Cote is an Assistant Professor in Materials Science & Engineering at Worcester Polytechnic Institute",
  description:
    "“Having the PLX-Benchtop allows us to significantly reduce the sample size required, not only saving time and material resources, but allowing us to generate mechanical data which we have yet to see on our materials”",
  imageAlt: "Dr Danielle Cote, Worcester Polytechnic Institute",
};

const testimonial_b = {
  name: "Professor Bill Clyne, FREng",
  position:
    "Emeritus Professor, Cambridge University | Chief Scientific Officer",
  description:
    "The team and I have a long history of working together on advanced, materials-related research projects. We're committed to continuing this tradition here at Plastometrex, and to supporting other materials scientists with our technical outreach work, our training courses and our conferences.",
};

const training = {
  title: "Training Courses and Conferences",
  byline:
    "We design and deliver bespoke technical courses and we organise and host conferences covering key areas in materials science, mechanics and mechanical testing. Courses are delivered in Cambridge, by our team of world leading materials scientists. See below for upcoming events.",
  secondaryButton: {
    text: "See our upcoming events",
    path: "/training-and-conferences",
  },
};

const preview = {
  title: "Papers on Profilometry-based Indentation Plastometry (PIP)",
  byline:
    "Our technology is built on more than ten years' worth of published scientific research work. You can access the published papers from the list below. We hope you enjoy reading them, and if you have any questions please get in touch",
  linkPath: "/research-papers",
};

const points = [
  {
    icon: <PieChart />,
    title: "Unique Insights",
    description:
      "Delve deeper into your studies on the relationship between microstructure and mechanical properties.",
  },
  {
    icon: <Rocket />,
    title: "Rapid, Simple Testing",
    description:
      "Minimal sample preparation and rapid testing turnaround times significantly accelerate your research activities.",
  },
  {
    icon: <BarChart />,
    title: "Rich Datasets",
    description:
      "Fully characterise the strength of your metallic materials to support the design of new alloy systems.",
  },
  {
    icon: <Squares />,
    title: "Testing Versatility",
    description:
      "Test small coupons, big coupons, off-cuts, cubes, cylinders, random shapes and real components.",
  },
];

const AcademiaPage = () => {
  const data = useStaticQuery(graphql`
    query {
      render: file(relativePath: { eq: "plastometer_c.jpeg" }) {
        childImageSharp {
          gatsbyImageData(height: 600, placeholder: BLURRED)
        }
      }
      demo: file(relativePath: { eq: "paper_discussion.png" }) {
        childImageSharp {
          gatsbyImageData(width: 850, placeholder: BLURRED)
        }
      }
      collage: file(relativePath: { eq: "collage_a.png" }) {
        childImageSharp {
          gatsbyImageData(width: 850, placeholder: BLURRED)
        }
      }
      cote: file(relativePath: { eq: "testimonials/testimonial_a.png" }) {
        childImageSharp {
          gatsbyImageData(width: 134, placeholder: BLURRED)
        }
      }
      billClyne: file(relativePath: { eq: "testimonials/bill_clyne.png" }) {
        childImageSharp {
          gatsbyImageData(width: 134, placeholder: BLURRED)
        }
      }
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/researchPapers/" } }
        sort: { fields: [frontmatter___date], order: DESC }
        limit: 3
      ) {
        edges {
          node {
            frontmatter {
              title
              date
              description
              pdf {
                publicURL
              }
              image {
                childImageSharp {
                  gatsbyImageData(width: 390)
                }
              }
            }
          }
        }
      }
    }
  `);

  const heroImage = getImage(data.render);
  const blockImage = getImage(data.demo);
  const trainingImage = getImage(data.collage);
  const testimonialImage = getImage(data.billClyne);
  const coteImage = getImage(data.cote);
  return (
    <Layout seo={seo} backgroundColorUnderneath="dark">
      {heroImage && (
        <FullScreenHero
          title={heroTitle}
          byline={heroByline}
          secondaryButton={heroSecondaryButton}
          image={heroImage}
        />
      )}
      <DotPointsWithIcons points={points} />
      {blockImage && (
        <TextButtonsImage
          title={blockTitle}
          byline={blockByline}
          secondaryButton={blockSecondaryButton}
          image={blockImage}
          imageAlt={blockImageAlt}
        />
      )}
      <Testimonial
        image={coteImage}
        name={testimonial.name}
        position={testimonial.position}
        description={testimonial.description}
        imageAlt={testimonial.imageAlt}
      />
      <Margin />
      <KnowledgeCentrePreview headingDetails={preview} data={data} />
      {trainingImage && (
        <TextButtonsImage
          title={training.title}
          byline={training.byline}
          secondaryButton={training.secondaryButton}
          image={trainingImage}
          demoButton={false}
        />
      )}
      <Testimonial
        image={testimonialImage}
        name={testimonial_b.name}
        position={testimonial_b.position}
        description={testimonial_b.description}
      />
      <Videos />
      <NewsletterWrapper>
        <Newsletter />
      </NewsletterWrapper>
    </Layout>
  );
};

export default AcademiaPage;

const NewsletterWrapper = styled("div", { "@md": { padding: "100px 0" } });
const Margin = styled("div", { marginTop: 0, "@md": { marginTop: 100 } });
