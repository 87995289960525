import { Link } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React, { Fragment, ReactNode } from "react";
import {
  detailSmall,
  heading100,
  heading150,
  heading300,
  paragraphTextMedium,
  paragraphTextSmall,
} from "../../stitches/combinedStyles";
import { styled } from "../../stitches/stitches.config";
import Button, { ButtonProps } from "./Button/Button";

const demoText = `Book a demo`;

interface Props {
  title: string;
  byline: ReactNode;
  demoButton?: boolean;
  secondaryButton: {
    text: string;
    path: string;
    tier?: ButtonProps["tier"];
    padding?: boolean;
  };
  image?: IGatsbyImageData;
  video?: string;
  imageAlt?: string;
  hideOnMobile?: boolean;
  variant?: "small";
}

const TextButtonsImage = ({
  title,
  byline,
  demoButton = true,
  secondaryButton,
  image,
  video,
  hideOnMobile,
  imageAlt,
  variant,
}: Props) => {
  const bylineArray = React.Children.toArray(byline);
  const small = variant === "small";
  return (
    <Container small={small}>
      <TextContent>
        <Title>{title}</Title>
        {hideOnMobile || (
          <MobileImageWrapper>
            {image && (
              <GatsbyImage image={image} alt={imageAlt ? imageAlt : ""} />
            )}
          </MobileImageWrapper>
        )}
        {bylineArray.map((byline, i) =>
          typeof byline === "string" ? (
            <Byline key={i} small={small}>
              {byline}
            </Byline>
          ) : (
            <Fragment key={i}>byline</Fragment>
          )
        )}
        <ButtonsContainer>
          <DemoButtonWrapper removed={!demoButton}>
            <Link to="/book-a-demo">
              <Button size="medium" tier="primary">
                {demoText}
              </Button>
            </Link>
          </DemoButtonWrapper>
          <MobileButtonWrapper removedMobile={demoButton}>
            <Link to={secondaryButton.path}>
              <Button
                size="medium"
                tier={secondaryButton.tier || "secondary"}
                arrow
                noPadding={!demoButton && !secondaryButton.padding}
              >
                {secondaryButton.text}
              </Button>
            </Link>
          </MobileButtonWrapper>
        </ButtonsContainer>
      </TextContent>
      {image && (
        <DesktopImageWrapper>
          <GatsbyImage image={image} alt={imageAlt ? imageAlt : ""} />
        </DesktopImageWrapper>
      )}
      {video && (
        <VideoWrapperDesktop>
          <Video
            src={video}
            width="100%"
            height="100%"
            frameBorder="0"
            allow="autoplay; fullscreen; picture-in-picture"
          />
        </VideoWrapperDesktop>
      )}
    </Container>
  );
};

export default TextButtonsImage;

const Container = styled("div", {
  maxWidth: 800,
  width: "90%",
  margin: "auto",
  paddingBottom: 150,
  paddingTop: 50,
  "@lg": {
    paddingTop: 150,
    paddingBottom: 150,
    maxWidth: 1120,
    display: "flex",
    width: "95%",
    justifyContent: "space-between",
    alignItems: "center",
  },
  variants: {
    small: {
      true: {
        paddingTop: 75,
        paddingBottom: 75,
      },
    },
  },
});
const TextContent = styled("div", {
  position: "relative",
  zIndex: 2,
  "@lg": {
    width: "60%",
    maxWidth: 468,
    marginRight: 40,
  },
});
const Title = styled("h4", {
  ...heading100,
  marginBottom: 16,
  "@lg": {
    ...heading300,
    letterSpacing: -0.76,
    marginTop: 0,
  },
});
const Byline = styled("p", {
  ...paragraphTextSmall,
  "@lg": {
    ...paragraphTextMedium,
  },

  variants: {
    small: {
      true: {
        ...detailSmall,
        "@sm": {
          ...paragraphTextSmall,
        },
      },
    },
  },
});
const MobileImageWrapper = styled("div", {
  display: "block",
  borderRadius: 10,
  overflow: "hidden",
  "@lg": {
    display: "none",
  },
});
const DesktopImageWrapper = styled("div", {
  display: "none",
  maxWidth: 497,
  width: "50%",
  borderRadius: 10,
  overflow: "hidden",
  "@lg": {
    display: "block",
  },
});

const VideoWrapperDesktop = styled("div", {
  padding: "30% 0 0 0",
  position: "relative",
  flex: "1 0 50%",
  borderRadius: 4,
  overflow: "hidden",
  display: "none",
  "@lg": {
    display: "block",
  },
});

const Video = styled("iframe", {
  position: "absolute",
  top: 0,
  left: 0,
  marginLeft: 30,
  "@md": {
    borderRadius: 4,
    overflow: "hidden",
  },
});

const ButtonsContainer = styled("div", {
  alignItems: "center",
  display: "flex",
});
const MobileButtonWrapper = styled("div", {
  variants: {
    removedMobile: {
      true: {
        display: "none",
        "@lg": {
          display: "block",
        },
      },
    },
  },
});
const DemoButtonWrapper = styled("div", {
  variants: {
    removed: {
      true: {
        display: "none",
      },
    },
  },
});
